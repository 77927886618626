export const addIubendaCookieBanner = (notice, siteId, cookiePolicyId, lang) => {

  if (notice == true && siteId && cookiePolicyId) {

    let html = "\n" + "var _iub = _iub || [];" + "\n";
    html += '_iub.csConfiguration = {"siteId":' + siteId + ',"cookiePolicyId":' + cookiePolicyId + ',"lang":"' + lang + '"};' + "\n";

    let scripts = [
      {
        innerHTML: html,
        type: "text/javascript"
      },
      {
        src: "https://cs.iubenda.com/autoblocking/" + siteId + ".js",
        type: "text/javascript"
      },
      {
        src: "//cdn.iubenda.com/cs/gpp/stub.js",
        type: "text/javascript"
      },
      {
        src: "//cdn.iubenda.com/cs/iubenda_cs.js",
        type: "text/javascript",
        async: true
      }
    ];

    useHead({
      script: scripts
    });
  }
};