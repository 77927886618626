<template>
  <div
    v-if="showBanner"
    class="group custom-notification py-4"
    :class="`custom-notification--${data.banner_type}`"
  >
    <button
      v-if="data.dismissible"
      @click="onClick"
      class="absolute right-0 top-0 m-2.5 w-8 h-8 opacity-70 group-hover:opacity-100"
    >
      <NuxtImg
        loading="lazy"
        alt="cross icon"
        width="32"
        height="32"
        src="/icons/cross.svg"
      />
    </button>
    <div class="shrink-0 px-2 pb-6 md:pb-0 items-center flex justify-center">
      <NuxtImg
        loading="lazy"
        alt=""
        :src="data.icon.permalink ?? ''"
        class="w-9 h-9"
        width="32"
        height="32"
      />
    </div>
    <div v-html="data.text" class="px-4"></div>
  </div>
</template>
<script setup>
const props = defineProps({
  data: Object,
  index: Number,
});
// cookie to expire 1 day
const cookieStore = ref(86400);
const showBanner = useCookie(props.data.title, {
  default: () => true,
  maxAge: cookieStore.value,
});

function onClick() {
  showBanner.value = false;
}
</script>

<style scoped>
.custom-notification--success {
  @apply bg-notification-success border-primary-100;
}

.custom-notification--info {
  @apply border-orange bg-notification-info;
}

.custom-notification--danger {
  @apply bg-notification-error border-danger;
}
</style>
